import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import useAudio from '../../hooks/use-audio'
import { Link } from 'gatsby'

const HojaPage = () => {
  useAudio("https://elucubraciones-xocen.s3.us-east-2.amazonaws.com/v1/cigarras.mp3")
  return (
    <Layout>
      <nav class="navbar">
        <Link class="navbar-brand" to="/">Volver</Link>
      </nav>
      <SEO title="Hoja" />
      <img src="https://elucubraciones-xocen.s3.us-east-2.amazonaws.com/v1/xocen-10.jpg" alt="Arbol" className="img-fluid" />
      <div className="row ">
        <div className="col-12 col-md-8">
          <h1 className="text-center mt-5 mb-5">Acto Hoja</h1>
        </div>
        <div className="col-12">
          <div className="row no-gutters align-items-end">
            <div className="col-12 col-md-9">
              <img className="img-fluid" src="https://elucubraciones-xocen.s3.us-east-2.amazonaws.com/v1/xocen-15.jpg" alt="Arbol" />
            </div>
            <div className="col-12 col-md-3">
              <img src="https://elucubraciones-xocen.s3.us-east-2.amazonaws.com/v1/xocen-17.jpg" alt="Arbol" />
            </div>
          </div>
          <div className="row no-gutters">
            <div className="col-12 col-md-3">
            </div>
            <div className="col-12 col-md-3">
              <img className="img-fluid" src="https://elucubraciones-xocen.s3.us-east-2.amazonaws.com/v1/xocen-22.jpg" alt="Arbol" />
            </div>
            <div className="col-12 col-md-3">
              <img className="img-fluid" src="https://elucubraciones-xocen.s3.us-east-2.amazonaws.com/v1/xocen-25.jpg" alt="Arbol" />
            </div>
            <div className="col-12 col-md-3">
              <img className="img-fluid" src="https://elucubraciones-xocen.s3.us-east-2.amazonaws.com/v1/xocen-1.jpg" alt="Arbol" />
            </div>
          </div>
        </div>
        <div className="col-12 col-md-6  offset-md-1">
          <p>
            El <em>Chachaak waaj</em> se prepara en un horno en la tierra, un hueco que acoge la leña y las piedras calientes, es en el píib donde se cocina este platillo para los finados, los rezos y los domingos. Lleva gallina, tomate, epazote, pimienta, ajo, orégano y
            clavo. La masa de maíz con achiote que le da color será el primer cobijo del majar, después se envuelve con hojas de plátano, que una vez que hayan pasado por fuego podrán doblarse sin romperse.
                    <br />
            <br />Las hojas de plátano servirán de tapa en la casa de tierra donde los envoltorios-hoja que abrazan la masa esperarán que la tierra y el fuego hagan su trabajo.
                </p>
          <br /> <br />
          <p class="text-center">
            Hoja cobijo, hoja alimento, hoja cura.
                    <br />
            <br /> Hoja sedante, follaje agrio, hojas de olor, hierbas santas.</p>
        </div>
      </div>

    </Layout>
  )
}

export default HojaPage
